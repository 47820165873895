<script lang="ts">
	import { tweened } from 'svelte/motion';
    import { cubicOut } from 'svelte/easing';

    export let progress;
    export let backgroundColor = 'white';

	const localProgress = tweened(0, {
        duration: 300,
        easing: cubicOut
    });

    $: $localProgress = progress != undefined ? progress : 0;

</script>

<div {...$$restProps}>
    <div class="flex items-center">
        <div class="flex-1">
            <div class="overflow-hidden rounded-full bg-{backgroundColor} mr-8">
                <div class="h-6 rounded-full bg-primary-dark"  style="width: {$localProgress * 100}%"></div>
            </div>
        </div>
        <div class="flex-none text-12 text-dark-grey">{Math.round($localProgress * 100)}%</div>
    </div>
</div>

