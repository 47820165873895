<script lang="ts">
</script>

<div {...$$restProps}>
  <div class="h-[300px] rounded-2xl flex flex-col transition-all duration-200 shadow-md hover:-translate-y-4 hover:shadow-2xl overflow-hidden relative  bg-white">
    <div class="relative w-full aspect-[20/13] overflow-hidden bg-light-grey">
    </div>
    <div class="h-8 bg-light-grey"></div>
    <div class="p-16 min-h-[150px]">
        <div class="text-light-grey text-14 font-bold text-ellipsis overflow-hidden bg-light-grey">Placeholder</div>
    </div>
  </div>
</div>

