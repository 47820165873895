<script lang="ts">
    import Icon from "@lernetz/svelte-icon/Icon.svelte";
    import { Route } from "@lernetz/svelte-router";
    import { t } from "@lernetz/svelte-translate";
    import { get } from "svelte/store";
    import DetailHeader from "../components/DetailHeader.svelte";
    import Material from "../components/Material.svelte";
    import WidthBox from "../components/WidthBox.svelte";
    import type { MaterialSearchResponse } from "../gateway/FlinkGateway";
    import { data, flinkGateway } from "../services";
    import DetailHeaderSubnavigation from "../components/DetailHeaderSubnavigation.svelte";
    import MaterialDummy from "../components/MaterialDummy.svelte";

    export let slug:string;

    let conduction = get(data.conduction);
    let module = conduction.modules.find( module => get(module).slug == slug );
    let subNavigationItems = [{route: '', title: 'learn-packages'}];
    let collectionMaterialPromise:Promise<MaterialSearchResponse>;

    function getCollectionMaterials() {
        collectionMaterialPromise = flinkGateway.searchCollectionMaterials( get($module.collection)?.publicId, {
          limit: 19,
          offset: 0,
          query: "",
          sortDir: "desc",
          filters: {},
          progress: true
        } );
    }

    getCollectionMaterials();

</script>

<svelte:window on:visibilitychange={getCollectionMaterials} />
<DetailHeader padding={false} backlinkText={"module-overview"} baseSegment={"modules"} subNavigationItems={subNavigationItems} />

<WidthBox paddingBottom={false} paddingHorizontal={false}>
    <div class="mt-80 flex flex-col sm:flex-row sm:h-screen">
        <div class="sm:basis-1/3 sm:mx-0 border-r bg-light-grey border-medium-grey ">
            <div class="sm:sticky top-112">
                <div class="flex justify-center p-32 ">
                    <Icon name={$module.icon} class="w-144 h-144" />
                </div>
                <h2 class="px-16 pb-48 pt-24  md:text-24 lg:text-28 font-bold text-ellipsis overflow-hidden text-center">{$module.title}</h2>
            </div>
        </div>
        <div class="sm:basis-2/3">
            <div class="sm:hidden flex justify-center h-80 border-b border-t sm:border-t-0 border-medium-grey sticky top-80 bg-white z-50">
                <DetailHeaderSubnavigation subNavigationItems={subNavigationItems}></DetailHeaderSubnavigation>
            </div>
            <div class="mt-32 mx-16 sm:mx-32 lg:mx-48 pb-16 sm:pb-32 lg:pb-48 grid grid-cols-1 xs:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-x-24 gap-y-32 content-center">
                <Route path="/">
                    {#await collectionMaterialPromise}
                        <MaterialDummy />
                        <MaterialDummy />
                    {:then materialSearchResponse}
                        {#each materialSearchResponse.results.sort( (a,b) => get(get(a).material).title > get(get(b).material).title ?  1 : -1  ) as collectionMaterial}
                            <Material model={get(collectionMaterial).material} collection={$module.collection} />
                        {/each}
                    {:catch}
                        <div>{$t("no-learning-packages-found")}</div>
                    {/await}
                </Route>
            </div>
        </div>
    </div>
</WidthBox>




