<script lang="ts">
    import { Link } from "@lernetz/svelte-router";
    import { t } from "@lernetz/svelte-translate";
    import WidthBox from "./WidthBox.svelte";
    import Button from "./Button.svelte";
    import DetailHeaderSubnavigation from "./DetailHeaderSubnavigation.svelte";
    export let backlinkText:string;
    export let baseSegment:{};
    export let subNavigationItems:{ route: string, title: string }[];
    export let padding = true;
</script>

<nav class="bg-white fixed top-0 left-0 right-0 border-b border-medium-grey z-50">
    <WidthBox paddingHorizontal={padding} paddingBottom={false}>
        <div class="flex flex-row h-80 justify-between">
            <div class="basis-1/3 flex items-center {!padding ? 'pl-16 sm:pl-32 lg:pl-48' : ''}">
                <Link to="/{baseSegment}">
                    <Button icon="arrow-left" iconPosition="left">{$t(backlinkText)}</Button>
                </Link>
            </div>
            <div class="hidden sm:flex basis-2/3 border-l border-medium-grey">
                <DetailHeaderSubnavigation subNavigationItems={subNavigationItems}></DetailHeaderSubnavigation>
            </div>
        </div>
    </WidthBox>
</nav>
