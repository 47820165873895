<script lang="ts">
    import { t } from "@lernetz/svelte-translate";
    import Header from "../components/Header.svelte";
    import WidthBox from "../components/WidthBox.svelte";
    import { data } from "../services";
    import House from "../components/House.svelte";
    import { get } from "svelte/store";

    let conduction = get(data.conduction);
    let houses = conduction.houses;
</script>


<Header title={$t("houses")} />
<WidthBox>
    <div class="mt-112">
        <div class="text-almost-black text-16">{$t("choose-house")}</div>
        <div class="mt-32 grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-16 sm:gap-24 content-center">
            {#each houses as house}
                <House model={house} />
            {/each}
        </div>
    </div>
</WidthBox>
