<script lang="ts">
    import type { Progress } from "../gateway/models";
    import { requests } from "../services";
    import ProgressBar from "./ProgressBar.svelte";
    import { Asset } from "@lernetz/svelte-image";

    export let model:Progress;
    export let index:number;


</script>

<div class:hidden={index >= 1} class:sm:block={index == 1} class:lg:block={index == 2}>
  <a href="{$model.metadata.link}" class="h-[300px] rounded-2xl flex flex-col transition-all duration-200 shadow-md hover:-translate-y-4 hover:shadow-2xl overflow-hidden relative" target="_blank">
    <ProgressBar progress="{$model.value}" backgroundColor={"light-grey"} class="absolute bottom-16 left-16 right-16" />

    <div class="relative w-full aspect-[20/13] overflow-hidden" style:background-color={$model.metadata.color}>
      {#if $model.metadata.cover}
        <Asset
          class="w-full h-full absolute inset-0 object-cover z-0"
          request={requests.flink.assets}
          asset={$model.metadata.cover}
          preset="card.webp"
          breakpoints={{ 300: "card-300.webp" }}
          placeholder="placeholder.webp"
        />
      {/if}
    </div>
    <div class="h-8" style:background-color={$model.metadata.color}></div>
    <div class="p-16 min-h-[150px]">
        <span class="text-almost-black text-14 font-bold text-ellipsis overflow-hidden">{$model.metadata.title}</span>
    </div>
  </a>

</div>

