<script lang="ts">
    import { t } from "@lernetz/svelte-translate";
    import Header from "../components/Header.svelte";
    import WidthBox from "../components/WidthBox.svelte";
    import { get } from "svelte/store";
    import { data } from "../services";
    import LibraryItem from "../components/LibraryItem.svelte";


    let libraryItems = data.libraryItems;
</script>


<Header title={$t("library")} />
<WidthBox>
    <div class="mt-112">
        <div class="text-almost-black text-16">{$t("choose-library-item")}</div>
        <div class="mt-32 grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-24 sm:gap-32 content-center">
            {#each libraryItems as item}
                <LibraryItem model={item} />
            {/each}
        </div>
    </div>
</WidthBox>



