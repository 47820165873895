<script lang="ts">
    import { CircleProgress } from "@lernetz/svelte-progress";
    import { Link } from "@lernetz/svelte-router";
    import { t } from "@lernetz/svelte-translate";
    import MaterialDummy from "../components/MaterialDummy.svelte";
    import WidthBox from "../components/WidthBox.svelte";
    import { data, flinkGateway, modelGateway } from "../services";
    import LastVisitedMaterial from "../components/LastVisitedMaterial.svelte";
    import { get } from "svelte/store";
    import DashboardCard from "../components/DashboardCard.svelte";
    import { update_await_block_branch } from "svelte/internal";
    import type { Progress } from "../gateway/models";

    let progress = 0;
    let lastVisitedMaterialsPromise:Promise<Progress[]>;

    function getSharesPublicId() {
        let publicIds:string[] = [];

        get(data.conduction).modules.forEach( module => {
            publicIds.push(get(get(module).collection)?.publicId);
        });

        get(data.conduction).houses.forEach( house => {
            publicIds.push(get(get(house).collection)?.publicId);
        });

        return publicIds;
    }

    function updateOverallProgress() {
        return flinkGateway.shareProgresssAverage(getSharesPublicId()).then(result => {
            progress = result.average;
        });
    }

    function updateLastVisitedMaterials() {
        lastVisitedMaterialsPromise = flinkGateway.lastVisited();
    }

    function updateAll() {
        updateOverallProgress();
        updateLastVisitedMaterials();
    }

    updateAll();

</script>

<svelte:window on:visibilitychange={updateAll} />

<WidthBox>
    <div class="flex mt-32">
        <div class="flex-1">
            <!-- last visited grid -->
            <h1 class="text-14 text-almost-black">{$t("last-visited")}</h1>
            <div class="mt-16 grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-16 sm:gap-24 content-center">
                {#await lastVisitedMaterialsPromise}
                    <MaterialDummy />
                    <MaterialDummy class="hidden sm:block" />
                    <MaterialDummy class="hidden lg:block" />
                {:then materials}
                    {#each materials as material, index}
                        <LastVisitedMaterial model={material} index={index}  />
                    {/each}
                {:catch}
                    <div>{$t("no-learning-packages-found")}</div>
                {/await}
                <div class="hidden xl:flex" ></div>

                <div class="h-[300px] rounded-2xl border-solid border border-medium-grey flex flex-col justify-center items-center">
                    <h2 class="text-14 text-almost-black font-bold m-16">{$t("course")} {$t("buildinge-envelope-planner")}</h2>
                    <CircleProgress class="w-64" progress={progress} trackColor={"text-light-grey"} progressColor={"text-primary"}></CircleProgress>
                </div>

            </div>
        </div>
    </div>

    <div class="flex mt-32">
        <div class="flex-1">
            <h1 class="text-14 text-almost-black">{$t("learn")}</h1>
            <div class="mt-16 grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 gap-16 sm:gap-24 content-center">
                <DashboardCard to="/modules" title="modules" image="modules.png"></DashboardCard>
                <DashboardCard to="/houses" title="houses" image="houses.png"></DashboardCard>
                <DashboardCard to="/library" title="library" image="library.png"></DashboardCard>
            </div>
        </div>
    </div>
</WidthBox>
