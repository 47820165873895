<script>
  import Route from "@lernetz/svelte-router/components/Route.svelte";
</script>

<Route path="/">
  <div>Initialisation Failed. Go back to Teams</div>
</Route>



