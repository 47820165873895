<style>
  .button {
    @apply text-16 rounded-xl flex items-center gap-8 py-8 px-16 transition-all duration-200 focus:outline-primary focus:outline-2 focus:outline-offset-[3px] !box-border;
  }

  .button.primary {
    @apply text-primary hover:bg-flink-grey disabled:text-medium-grey disabled:cursor-default;
  }

  .button.secondary {
    @apply border border-primary bg-white hover:bg-flink-grey disabled:border-medium-grey disabled:text-medium-grey text-primary disabled:cursor-default;
  }

  .button.icon-left {
    @apply pl-16 pr-24;
  }

  .button.icon-right {
    @apply pl-24 pr-16;
  }

</style>

<script lang="ts">
  import { useActions, type SvelteActionArray } from "@lernetz/svelte-common/actions";
  import Icon from "@lernetz/svelte-icon/Icon.svelte";

  export let icon: string = undefined;
  export let iconPosition: "left" | "right" = "left";
  export let disabled: boolean = false;
  export let variant = "primary";

  export let use: SvelteActionArray = [];
  const hasText = $$slots.default;
  const iconClass = icon && hasText ? `icon-${iconPosition}` : hasText ? "" : "icon-only";
</script>

<button
  use:useActions={use}
  on:click
  {...$$restProps}
  class="button {variant} {iconClass} {$$restProps.class ?? ''}"
  {disabled}
>
  {#if icon && iconPosition === "left"}<Icon name={icon} class="w-24 h-24" />{/if}
  <slot />
  {#if icon && iconPosition === "right"}<Icon name={icon} class="w-24 h-24" />{/if}
</button>
