import { RequestBuilder, mapRequests } from "@lernetz/request";
import { defaultRequest } from "@lernetz/svelte-icon";
import { ModelGateway } from "@lernetz/svelte-model-api";
import { processIn } from "@lernetz/svelte-model-api/process";
import { historyMode } from "@lernetz/svelte-router";
import { FlinkGateway } from "./gateway/FlinkGateway";
import type { Conduction, LibraryItem, User } from "./gateway/models";
import { set } from "@lernetz/svelte-translate";


export let flinkGateway: FlinkGateway;
export let modelGateway: ModelGateway;

export var requests: {
    icons: RequestBuilder;
    assets: RequestBuilder,
    flink: {
        base: RequestBuilder,
        assets: RequestBuilder,
        collectionMaterial: RequestBuilder;
    },
    api: {
        model: RequestBuilder;
    };
};

export var data: {
    baseUrl: string;
    user: User;
    libraryItems: LibraryItem[];
    env: string;
    conduction: Conduction;
};

export async function setupData( init: { data: any, routes: any, lang: any; } ) {

    requests = mapRequests( init.routes );
    data = processIn( init.data );
    data = init.data;

    flinkGateway = new FlinkGateway( {
        base: requests.flink.base,
    }, );

    modelGateway = new ModelGateway( {
        base: requests.api.model
    } );

    set( "de", init.lang );

    historyMode( data.baseUrl );
    defaultRequest( requests.icons );
}



