<script>
  import Route from "@lernetz/svelte-router/components/Route.svelte";
  import DashboardPage from "./DashboardPage.svelte";
  import HousesPage from "./HousesPage.svelte";
  import LibraryPage from "./LibraryPage.svelte";
  import ModuleDetailPage from "./ModuleDetailPage.svelte";
  import ModulesPage from "./ModulesPage.svelte";
  import HouseDetailPage from "./HouseDetailPage.svelte";
  import { data, modelGateway } from "../services";
  import { Redirect } from "@lernetz/svelte-router";
  import ConductionPage from "./ConductionPage.svelte";

</script>

<Route path="/dashboard">
  <DashboardPage />
</Route>

<Route path="/modules">
  <ModulesPage />
</Route>

<Route path="/modules/:slug/*" let:params>
  <ModuleDetailPage slug={params.slug} />
</Route>

<Route path="/houses">
  <HousesPage />
</Route>

<Route path="/houses/:slug/*" let:params>
  <HouseDetailPage slug={params.slug} />
</Route>

<Route path="/library">
  <LibraryPage />
</Route>

<Route path="/conduction">
  <ConductionPage />
</Route>

{#if data.conduction == undefined}
  <Redirect path="/" to="/conduction" ></Redirect>
{:else}
    <Redirect path="/" to="/dashboard" ></Redirect>
{/if}


<style>
  :global(div.html-content) {

  }
  :global(div.html-content ul) {
    list-style-position: inside;
    list-style-type: disc;
  }
  :global(div.html-content ol) {
    list-style-position: inside;
    list-style-type: decimal;
  }
  :global(div.html-content a) {
    color: rgb(58, 65, 149);
    display: inline-block;
    background-color: white;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0.75rem;
    border-color: rgb(58, 65, 149);
    border-style: solid;
    border-width: 1px;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 200ms;
  }
  :global(div.html-content a:hover) {
    background-color: rgb(246, 246, 246)
  }
</style>


