<script lang="ts">
    import Icon from "@lernetz/svelte-icon/Icon.svelte";
    export let text:string;
    export let icon:string;
</script>

<div class="text-16 text-primary hover:bg-flink-grey transition-all duration-200 transform py-8 px-16 rounded-xl flex">
    <Icon name="{icon}" class="h-24 w-24"></Icon>
    <div class="ml-4">{text}</div>
</div>
