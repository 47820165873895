<script lang="ts">
    import Link from "@lernetz/svelte-router/components/Link.svelte";
    import { t } from "@lernetz/svelte-translate";

    export let to:string;
    export let title:string;
    export let image:string;
</script>



<Link to="{to}" class="h-[320px] rounded-2xl flex flex-col items-center transition-all duration-200 bg-light-grey hover:bg-medium-grey">
    <div class="font-bold text-18 mt-32 text-center">
        {$t(title)}
    </div>
    <img
        alt={$t(title)}
        class="object-contain h-240"
        src="/images/{image}"
    />
</Link>
