<script lang="ts">
    import { Link } from "@lernetz/svelte-router";
    import { requests } from "../services";
    import Image from "@lernetz/svelte-image/components/Image.svelte";
    import type { House } from "../gateway/models";

    export let model: House;
    let image = $model.image;

</script>


<Link to="/houses/{$model.slug}" class="h-[350px] rounded-2xl flex flex-col transition-all duration-200 bg-light-grey hover:bg-medium-grey relative overflow-hidden">
    <div class="m-auto">
        <div class="relative w-full aspect-[1/1]">
            {#if $model.image}
                <Image
                    class="w-full absolute inset-0 object-cover z-0"
                    request={requests.assets}
                    vars={{ file_name: $image?.file_name, preset: "medium.png" }}
                />
            {/if}
        </div>
        <div class="px-24 pb-24">
            <div class="h-[48px] flex items-center justify-center">
                <span class="text-almost-black text-16 text-center font-bold text-ellipsis overflow-hidden">{$model.title}</span>
            </div>
        </div>
    </div>
</Link>
