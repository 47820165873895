<script lang="ts">
    import { Link } from "@lernetz/svelte-router";
    import ProgressBar from "./ProgressBar.svelte";
    import type { Module, Progress } from "../gateway/models";
    import Icon from "@lernetz/svelte-icon/Icon.svelte";
    import { flinkGateway } from "../services";
    import { get } from "svelte/store";

    export let model: Module;
    let collection = $model.collection;
    let progress = 0;

    flinkGateway.shareProgress( $collection?.publicId).then( res => {
        progress = get(res).value;
    });

</script>


<Link to="/modules/{$model.slug}" class="h-[320px] rounded-2xl flex transition-all duration-200 bg-light-grey hover:bg-medium-grey relative">

    <div class="absolute top-16 left-16 flex h-32 w-32 items-center justify-center rounded-full bg-white">
        <span class="text-primary-dark text-18 font-bold">{$model.number}</span>
    </div>
    <ProgressBar progress="{progress}" class="absolute bottom-24 left-24 right-24"></ProgressBar>
    <div class="p-24">
        <div class="flex justify-center ">
            <Icon name={$model.icon} class="w-128 h-128" />
        </div>
        <div class="h-[70px] mt-16 flex items-center">
            <span class="text-almost-black text-16 font-bold text-ellipsis overflow-hidden">{$model.title}</span>
        </div>
    </div>
</Link>
