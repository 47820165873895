<script lang="ts">
    import { get } from "svelte/store";
    import WidthBox from "../components/WidthBox.svelte";
    import type { Conduction } from "../gateway/models";
    import { data, modelGateway } from "../services";
    import { DropDown, DropDownRadioGroup, DropDownRadioItem } from "@lernetz/svelte-overlays";
    import Icon from "@lernetz/svelte-icon/Icon.svelte";
    import { t } from "@lernetz/svelte-translate";
    import Button from "../components/Button.svelte";

    let currentConduction = data.conduction;

    function getConductions():Promise<Conduction[]> {
        return modelGateway.query({
            where: {
                modelName: "App\\Models\\Conduction",
            }
        } ).then( ( conductions:Conduction[]) => {
            // if there is only one conduction -> set it an go further
            if(conductions.length == 1) {
                updateConduction(conductions[0]).then( () => {
                    gotoDashboard();
                });
            };
            return conductions;
        });
    }

    function updateConduction(conduction: Conduction) {
        return modelGateway.update(data.user, {
            conduction
        }).then( () => {
            currentConduction = conduction;
        });
    }

    function gotoDashboard() {
        window.location.href = '/dashboard';
    }

</script>

<WidthBox>

    <div class="h-screen flex justify-center items-center">


        <div class="flex flex-col items-start">

            <div>{$t("choose-conduction")}</div>

            <div class="mt-8">

                {#await getConductions() }
                    <div>{$t("loading")}</div>
                {:then conductions}
                    <DropDown let:overlay>
                        <button
                            use:overlay.trigger
                            class="text-16 text-primary hover:bg-flink-grey transition-all duration-200 transform py-8 px-16 rounded-xl flex"
                        >
                            {#if $currentConduction == undefined}
                                {$t("choose")}
                            {:else}
                                {$currentConduction.title}
                            {/if}
                            <Icon class="w-24 h-24 mr" name="chevron-down" />
                        </button>
                        <svelte:fragment slot="content">
                            <div class="overflow-hidden rounded-lg bg-white shadow-lg py-10 flex flex-col gap-4 min-w-[160px] max-h-[100vh] overflow-y-auto">
                                <DropDownRadioGroup
                                    value={$currentConduction?.id}
                                    on:change={(e) => updateConduction(conductions.find( conduction => get(conduction).id == e.detail ))}
                                >
                                {#each conductions as conduction}
                                    <div class="px-10">
                                        <DropDownRadioItem
                                            value={get(conduction).id}
                                            class="w-full p-6 text-15 bg-white flex items-center gap-4 text-left rounded-md aria-checked:bg-light-grey aria-checked:text-primary  focus:bg-light-grey hover:bg-light-grey cursor-pointer"
                                        >
                                            {get(conduction).title}
                                        </DropDownRadioItem>
                                    </div>
                                {/each}
                                </DropDownRadioGroup>

                            </div>
                        </svelte:fragment>
                    </DropDown>
                {/await}

            </div>


            <Button on:click={gotoDashboard} disabled={$currentConduction == undefined} class="mt-8">{$t("next")}</Button>

        </div>

    </div>

</WidthBox>
