<script lang="ts">
    import Link from "@lernetz/svelte-router/components/Link.svelte";
    export let subNavigationItems:{ route: string, title: string }[];
    import { t } from "@lernetz/svelte-translate";
</script>

<div class=" sm:ml-32 lg:ml-48 flex items-stretch">
    {#each subNavigationItems as item}
        <Link to="{item.route}" exact={true} classActive="border-primary" classInactive="border-light-grey" class="mx-8 border-light-grey border-b-4 flex items-center hover:border-primary">
            <span class="px-16 text-primary text-center">{$t(item.title)}</span>
        </Link>
    {/each}
</div>
