import RootPage from "./pages/RootPage.svelte";
import { data, modelGateway, setupData } from "./services";
import { app, authentication } from "@microsoft/teams-js";
import TeamsInitialisationFailedPage from "./pages/TeamsInitialisationFailedPage.svelte";
import jwtDecode, { type JwtPayload } from "jwt-decode";
import { get } from "svelte/store";
import type { Conduction, User } from "./gateway/models";


export async function init( init ) {


    if( init.data.env == 'local' ) {
        setupData( init );
        startUp();
    } else {
        // Initialize the Microsoft Teams SDK
        app.initialize().then( () => {

            // inject bearer token
            authentication.getAuthToken().then( token => {
                init.routes.api.model.headers[ "Authorization" ] = "Bearer " + token;
                setupData( init );
                startUp();
            } );

        } ).catch( e => {
            console.log( 'Error initialising teams' );
            let root = new TeamsInitialisationFailedPage( {
                target: document.body,
            } );
        } );
    }

}

async function startUp() {
    let username = null;

    if( data.user == undefined ) {
        let token = await authentication.getAuthToken();
        let decodedToken = jwtDecode( token ) as any;
        username = decodedToken.upn;
    } else {
        username = get( data.user ).username;
    }

    loadUser( username ).then( () => {
        let root = new RootPage( {
            target: document.body,
        } );
    } );
}


function loadUser( username ) {

    return modelGateway
        .query( {
            where: {
                modelName: "App\\Models\\User",
                username: username,
            },
            relations: {
                conduction: {
                    modules: {
                        collection: true,
                    },
                    houses: {
                        collection: true,
                        image: true
                    }
                }
            },
        } ).then( ( m ) => {
            data.user = m[ 0 ] as User;
            data.conduction = get( data.user ).conduction;
        } );
}

