<script lang="ts">
    import Link from "@lernetz/svelte-router/components/Link.svelte";
    import { t } from "@lernetz/svelte-translate";
    import WidthBox from "./WidthBox.svelte";
    import HeaderButton from "./HeaderButton.svelte";
    import Button from "./Button.svelte";
    export let title:string;
</script>

<nav class="bg-white fixed top-0 left-0 right-0 border-b border-medium-grey z-10">
    <WidthBox paddingBottom={false}>
        <div class="flex h-80 justify-between">
            <div class="flex">
                <div class="flex flex-shrink-0 items-center">
                    <Link to="/dashboard" class="mr-8" >
                        <Button icon="home" iconPosition="left">Dashboard</Button>
                    </Link>
                    <h1 class="text-28 text-almost-black font-bold pl-24 border-l border-medium-grey">{$t(title)}</h1>
                </div>
            </div>
        </div>
    </WidthBox>
</nav>
