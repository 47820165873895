<script lang="ts">
    import { requests } from "../services";
    import Image from "@lernetz/svelte-image/components/Image.svelte";
    import type { LibraryItem } from "../gateway/models";

    export let model: LibraryItem;
    let image = $model.image;
    let collection = $model.collection;
</script>

<a
    href={$collection.link}
    target="_blank"
    class="flex flex-col justify-between gap-16 h-256 group cursor-default"
>
    <div
        class="flex-auto rounded-3xl border-medium-grey relative transition transform group-hover:-translate-x-4 group-hover:-translate-y-4"
    >
        <div class="w-full h-full relative">
            <div
                aria-hidden="true"
                class="w-full h-full inset-0 absolute rounded-3xl top-10 left-10 transform bg-white group-hover:translate-x-6 group-hover:translate-y-6 transition-all -z-10 border border-medium-grey shadow-md"
            ></div>
            <div
                aria-hidden="true"
                class="w-full h-full inset-0 absolute rounded-3xl top-[5px] left-[5px] transform group-hover:translate-x-[3px] group-hover:translate-y-[3px] -z-15 transition-all bg-white border border-medium-grey"
            ></div>
            <div
                class="w-full h-full inset-0 rounded-3xl overflow-hidden absolute bg-white z-50 border border-medium-grey flex items-center justify-center"
            >
                {#if $model.image}
                    <Image
                        class="w-full h-full inset-0 object-cover object-center absolute rounded-3xl"
                        request={requests.assets}
                        vars={{
                            file_name: $image?.file_name,
                            preset: "medium.png",
                        }}
                    />
                {/if}
            </div>
            <div
                class="w-full h-full inset-0 rounded-3xl absolute opacity-0 group-hover:opacity-100 transition bg-[rgba(0,0,0,0.4)] z-50 flex items-center justify-center"
            ></div>
        </div>
    </div>
    <div class="px-8">
        <div
            class="text-14 leading-5 font-bold text-almost-black line-clamp-2 transition"
        >
            {$model.title}
        </div>
    </div>
</a>
