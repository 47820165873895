<script lang="ts">
    import { t } from "@lernetz/svelte-translate";
    import { data } from "../services";
    import Module from "../components/Module.svelte";
    import Header from "../components/Header.svelte";
    import WidthBox from "../components/WidthBox.svelte";
    import { get } from "svelte/store";

    let conduction = get(data.conduction);
    let modules = conduction.modules;

</script>

<Header title={"modules"} />
<WidthBox>
    <div class="mt-112">
        <div class="text-almost-black text-16">{$t("choose-module")}</div>
        <div class="mt-32 grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-16 sm:gap-24 content-center">
            {#each modules as module}
                <Module model={module} />
            {/each}
        </div>
    </div>
</WidthBox>
