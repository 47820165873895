<script lang="ts">
    import type { Collection, Material } from "../gateway/models";
    import { flinkGateway, requests } from "../services";
    import ProgressBar from "./ProgressBar.svelte";
    import { Asset } from "@lernetz/svelte-image";

    export let model:Material;
    export let collection:Collection;

    function getMaterialUrl() {
      return requests.flink.collectionMaterial.vars({
        collectionId: $collection?.publicId,
        materialId: $model?.publicId,
      }).url
    }

</script>

<div>
  <a href="{getMaterialUrl()}" class="h-[300px] rounded-2xl flex flex-col transition-all duration-200 shadow-md hover:-translate-y-4 hover:shadow-2xl overflow-hidden relative" target="_blank">
    <ProgressBar progress="{$model.progress}" backgroundColor={"light-grey"} class="absolute bottom-16 left-16 right-16" />

    <div class="relative w-full aspect-[20/13] overflow-hidden" style:background-color={$model.color}>
      {#if $model.cover}
        <Asset
          class="w-full h-full absolute inset-0 object-cover z-0"
          request={requests.flink.assets}
          asset={$model.cover}
          preset="card.webp"
          breakpoints={{ 300: "card-300.webp" }}
          placeholder="placeholder.webp"
        />
      {/if}
    </div>
    <div class="h-8" style:background-color={$model.color}></div>
    <div class="p-16 min-h-[150px]">
        <span class="text-almost-black text-14 font-bold text-ellipsis overflow-hidden">{$model.title}</span>
    </div>
  </a>

</div>

